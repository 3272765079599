import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import styles from "./staticpage.module.scss"

class StaticPageTemplate extends React.Component {
  render() {
    const page = this.props.data.datoCmsOldalak
    const { defaultMetadata } = this.props.pageContext
    const pageDescription = defaultMetadata.fallbackSeo.description
    const pageTitle = page.cim + defaultMetadata.titleSuffix

    return (
      <Layout location={this.props.location}  readOnlyAPIToken={this.props.data.site.siteMetadata.readOnlyAPIToken}>
        <Helmet
          htmlAttributes={{ lang: 'hu' }}
          meta={[{ name: 'description', content: pageDescription }]}
          title={pageTitle}
        />

        <h1 className={styles.title}>{page.cim}</h1>

        <div className={styles.staticContent}>
          {page.leadNode.childMarkdownRemark.html && <p className={styles.lead} dangerouslySetInnerHTML={{ __html: page.leadNode.childMarkdownRemark.html }} />}
          <p dangerouslySetInnerHTML={{ __html: page.contentNode.childMarkdownRemark.html }} />
        </div>

      </Layout>
    )
  }
}

export default StaticPageTemplate

export const pageQuery = graphql`
  query StaticPagesBySlug($slug: String!) {
    site {
      siteMetadata {
        productionMode
        fullAPIToken
        readOnlyAPIToken
      }
    }

    datoCmsOldalak (slug: {eq: $slug}) {
      cim
      slug
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      leadNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
